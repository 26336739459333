import { Box, Container, Flex, Image } from '@energiebespaarders/symbols';
import mediaMin from '@energiebespaarders/symbols/styles/breakpoints';
import React from 'react';
import styled from 'styled-components';
import { padding } from '~/styles/mixins';
import savingsIllustration from '/public/img/homePage/proces-besparen.svg';
import installationIllustration from '/public/img/homePage/proces-installatie.svg';
import insightIllustration from '/public/img/homePage/proces-inzicht.svg';

const StyledSection = styled.section`
  ${padding(10, 'y')};
`;

const StyledIllustration = styled(Image)`
  max-width: 180px;

  ${mediaMin.md} {
    max-width: 240px;
  }
`;

const Process: React.FC = () => (
  <StyledSection>
    <Container textAlign="center">
      <h2>Vind snel de beste duurzame oplossingen voor je woning</h2>
      <Flex flexWrap={['wrap', 'wrap', 'nowrap']} mx={[0, 0, -2]} px={[0, 0, 2]}>
        <Box width={1} px={2}>
          <StyledIllustration
            src={insightIllustration}
            alt="Direct inzicht in jouw mogelijkheden"
          />
          <h3>Direct inzicht in jouw mogelijkheden</h3>
          <p>
            Je ziet online direct welke duurzame oplossingen mogelijk zijn voor jouw woning,
            inclusief prijsindicaties.{' '}
          </p>
        </Box>
        <Box width={1} px={2}>
          <StyledIllustration src={installationIllustration} alt="Coördinatie van installaties" />
          <h3>Regel alles op één plek</h3>
          <p>
            Regel de installatie van een (hybride) warmtepomp, zonnepanelen en isolerende
            maatregelen eenvoudig op één plek.
          </p>
        </Box>
        <Box width={1} px={2}>
          <StyledIllustration src={savingsIllustration} alt="Garantie en zekerheid" />
          <h3>Garantie en zekerheid</h3>
          <p>
            Wij zorgen ervoor dat de oplossingen geïnstalleerd worden door erkende, gecertificeerde
            installateurs. Met extra garantie op de uitvoer.
          </p>
        </Box>
      </Flex>
    </Container>
  </StyledSection>
);

export default Process;
