import { Box, Container, Flex, Image } from '@energiebespaarders/symbols';
import { Small } from '@energiebespaarders/symbols/helpers';
import React from 'react';
import styled from 'styled-components';
import { grayscaleCSSFilter, resetCSSFilters } from '~/styles/theme';
import type { PartnerKey } from '~/utils/constants';
import { DEB_PARTNERS } from '~/utils/debPartners';
import { shuffleArray } from '~/utils/shuffleArray';

type StyledLogoProps = {
  $maxWidth?: string;
  $maxHeight?: string;
  $height?: string;
};

export const StyledPartnerLogo = styled(Image)<StyledLogoProps>`
  ${grayscaleCSSFilter};
  max-width: ${x => x.$maxWidth || '120px'};
  max-height: ${x => x.$maxHeight || '48px'};
  height: ${x => x.$height || ''};
  opacity: 0.5;
  transition: all 0.25s ease;
  object-fit: contain;

  &:hover {
    ${resetCSSFilters};
    mix-blend-mode: normal;
    opacity: 1;
  }
`;

interface PartnersProps {
  partnerArray?: PartnerKey[];
  amountPerRow?: number;
  shuffleMax?: number;
}

const Partners: React.FC<PartnersProps> = ({
  partnerArray = DEB_PARTNERS.filter(partner => !partner.hide).map(partner => partner.key),
  amountPerRow = 5,
  shuffleMax = 12,
}) => {
  const filteredArray = shuffleMax ? shuffleArray(partnerArray).slice(0, shuffleMax) : partnerArray;
  return (
    <Container textAlign="center" py={5}>
      <Small>
        <p>Officieel en onafhankelijk partner van o.a.</p>
      </Small>
      <Flex flexWrap="wrap" justifyContent="center" alignItems="center" mx={-1}>
        {DEB_PARTNERS.filter(p => filteredArray.includes(p.key))
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(p => (
            <Box
              key={p.key}
              width={[1 / 2, 1 / Math.ceil(amountPerRow / 2), 1 / amountPerRow]}
              px={1}
              py={2}
            >
              <StyledPartnerLogo src={p.logo} alt={p.name} height={p.logoHeight} loading="lazy" />
            </Box>
          ))}
      </Flex>
    </Container>
  );
};

export default Partners;
